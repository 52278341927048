<template>
  <div class="setting">
    <div class="left_account">
      <div>
        <p style="font-size: large">{{ $t("message.email.currentaccount") }}</p>
      </div>
      <div class="card">
        <accountcard
          @deleteaccount="deleteaccount"
          :dataObj="emailAccountForm"
          :temp="2"
        ></accountcard>
      </div>
      <div>
        <p style="font-size: large">{{ $t("message.email.accountlist") }}</p>
      </div>
      <div style="overflow-y: auto; height: 450px">
        <ul>
          <li v-for="item in accountandemail" :key="item.id">
            <accountcard
              @deleteaccount="deleteaccount"
              v-if="item.id != emailAccountForm.id"
              :dataObj="item"
            ></accountcard>
          </li>
        </ul>
      </div>
    </div>
    <div class="right_set">
      <el-form>
        <div>
          <p style="font-size: large">
            {{ $t("message.email.emaildownload") }}
          </p>
        </div>
        <div class="card">
          <el-form :model="downloadForm" ref="downloadForm">
            <el-card class="box-card">
              <el-row>
                <p>{{ $t("message.email.downloadmailsetting") }}</p>
              </el-row>
              <el-row>
                <el-form-item
                  :label="$t('message.email.date')"
                  label-width="80px"
                  prop="sendmail_date"
                >
                  <el-select v-model="downloadForm.datetype" style="width: 80%">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @click="filterTime(item)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <span v-if="timeData">
                  <el-tag color="white" style="font-size: 15px">
                    {{ timeData }}
                  </el-tag>
                </span>
                <el-form-item label-width="80px" prop="sendmail_date">
                  <el-dialog
                    title="Choice Time"
                    v-model="dialogVisible"
                    width="40%"
                  >
                    <div style="padding-top: 10px" :data="timePo">
                      <el-date-picker
                        v-model="start"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="Choose the date and time"
                        :picker-options="pickerOptionsStart"
                        style="margin-right: 10px"
                        @change="startTimeStatus"
                      />
                      to
                      <el-date-picker
                        v-model="end"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="Choose the date and time"
                        :picker-options="pickerOptionsEnd"
                        style="margin-left: 10px"
                        @change="endTimeStatus"
                      />
                    </div>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="cancel">cancel</el-button>
                      <el-button type="primary" @click="saveTime"
                        >submit</el-button
                      >
                    </div>
                  </el-dialog>
                </el-form-item>
              </el-row>
              <el-row>
                <p>{{ $t("message.email.mailform") }}</p>
              </el-row>
              <el-row>
                <el-form-item label-width="50px" prop="download_type">
                  <el-radio-group v-model="downloadForm.download_type">
                    <el-radio label="all" value="all">ALL</el-radio>
                    <el-radio label="tonnage" value="tonnage">TONNAGE</el-radio>
                    <el-radio label="cargo" value="cargo">CARGO</el-radio>
                    <el-radio label="tct" value="tct">TCT</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :span="10"
                  ><el-button
                    size="medium"
                    type="success"
                    style="margin-left: 40%"
                    @click="downloadEmail"
                    >{{ $t("message.email.emaildownload") }}</el-button
                  ></el-col
                >
                <!-- <el-col :span="6">
                  <el-button
                   size="medium"
                   type="primary" 
                   style="width: 100px"
                   @click="stopDownload" 
                  >{{ $t("message.email.stop") }}</el-button>
                </el-col> -->
                
                <el-col :span="5"
                  ><el-button
                    size="medium"
                    type="danger"
                    style="width: 100px"
                    @click="resetdown"
                    >{{ $t("message.email.reset") }}</el-button
                  ></el-col
                >
                <!--                                <el-col :span="2"><el-button size="medium" type="danger"  style="width:100px;" @click="getMailTask">检索</el-button></el-col>-->
              </el-row>
            </el-card>
          </el-form>
        </div>
        <div class="card" v-if="downloadflag == true">
          <el-form>
            <el-card class="box-card">
              <el-row
                ><p>{{ $t("message.email.DownloadProgressMeter") }}</p>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label-width="50px">
                    <div style="height: 100px; width: 100%" v-loading="loading">
                      <span>{{ downloadForm.message }}</span>
                    </div>
                    <el-progress
                      v-show="progressflag"
                      :percentage="percentage"
                    ></el-progress>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--                            <el-row><p>&emsp;&emsp;&emsp;{{$t('message.email.accountlist')}}下载提示</p></el-row>-->
              <el-row v-show="progressflag">
                <el-col :span="19"
                  ><el-button
                    size="medium"
                    type="success"
                    style="margin-left: 70%"
                    :disabled="isButtonDisabled || isBothButtonsDisabled"
                    @click="continuedown"
                    >{{ $t("message.email.continue") }}</el-button
                  ></el-col
                >  
                <el-col :span="2"
                  ><el-button
                    size="medium"
                    type="danger"
                    style="width: 100px"
                    :disabled="!isButtonDisabled || isBothButtonsDisabled"
                    @click="stopDownload" 
                    >{{ $t("message.email.pause") }}</el-button
                  ></el-col
                >
              </el-row> 
            </el-card>
          </el-form>
        </div>
        <div class="card"  v-show="downloadComplete" >
          <el-form>
            <el-card class="container-card">
              <div style="height: 80px; width: 100%" v-loading="loading" class="centered-content">
                      <span>     All {{ downloadForm.total }} emails have been downloaded</span>
                    </div>
              <el-button
                    size="medium"
                    type="primary"
                    style="margin-left: 80%"
                    @click="gotoextract"
                    >{{ $t("message.email.extractemail") }}</el-button>
              
            </el-card>
          </el-form>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import accountcard from "../../components/account_email/Accountcard";
import {
  getinfouseraccount,
  getemailaccount,
  updateuseraccount,
  connectaccount,
  emaildownload,
  getemailtask,
  getemailtaskbyId,
  emailstopdownload,
  emaildownloadrate,
  DeletRepeatEmail,
} from "../../api/emailextract";
export default {
  // props:{
  //     temp:3,
  //     //dataObj:Object
  //   },
  components: { accountcard },
  data() {
    return {
      dataObj: {
      id: null, // 初始化 id，或者根据你的需要设置初始值
      // 其他属性...
    },
     // flag:false,
     isBothButtonsDisabled:false,
      isButtonDisabled: false,
      downloadComplete: false,
      downloadflag: "false",
      extractflag: "false",
      progressflag: false,
      /*jx add */
      percentage : 0,       // 为进度条添加标志位
      /**/
      loading: true,
      bar: "",
      currentId: "",
      start: "",
      end: "",
      timeData: "",
      // jx modify 24.03.06 设置默认值为“今天”
      startData: new Date().toISOString().slice(0, 10),
      dialogVisible: false,
      /*jx add*/
      timer : null,         // 获取下载进度的定时器
      $alterrepeat : null,  // 重复邮件警告提示框
      $messagerepeat : null,// 重复邮件提示框
      /**/
      accountandemail: [],
      emailAccountForm: {
        account_nickname: "",
        account_email: "",
        server_protocol_type: "",
        mail_server_provider: "",
        incoming_mail_server: "",
        username: "",
        login_passwd: "",
        server_timeout: "",
        incoming_server_port: "",
        jiami: "",
        is_ssl: false,
      },
      downloadForm: {
        emailaccount_id: "",
        download_type: "all",
        sendmail_date: "",
        datetype: "今天",
        message: "",
        total:"",
      },
      options: [
        {
          value: "今天",
          label: "今天",
          interval: 0,
        },
        {
          value: "昨天",
          label: "昨天",
          interval: 1,
        },
        {
          value: "前天",
          label: "前天",
          interval: 2,
        },
        {
          value: "7天内",
          label: "7天内",
          interval: 6,
        },
        {
          value: "自定义",
          label: "自定义",
          interval: -1,
        },
      ],
      pickerOptionsStart: {
        disabledDate: (time) => {
          const endDateVal = this.end;
          if (endDateVal) {
            return time > new Date(endDateVal).getTime();
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          const beginDateVal = this.start;
          // console.log(beginDateVal)
          if (beginDateVal) {
            return time < new Date(beginDateVal).getTime();
          }
        },
      },
    };
  },
  methods: {
    gotoextract(){
      this.dataObj=this.emailAccountForm;
      
        this.$router.push(`/extract/emailextract/${this.dataObj.id}`)
        //console.log('dataObj.id:', this.dataObj.id);
      },
    deleteaccount() {
      this.getMailList();
    },
    opendown() {
      this.downloadflag = true;
    },
    openextract() {
      this.extractflag = true;
    },
    continuedown()
    {
      if (!this.downloadComplete) {
      // 处理继续下载逻辑
      // ...

      // 禁用继续按钮，启用停止按钮
      this.isButtonDisabled = true;
    }
      this.isButtonDisabled = true;
      
    },
  //停止下载，新增 stopDownload 方法
  async stopDownload() {
  // this.flag=true;
  
    this.isButtonDisabled = false;
    this.downloadComplete = true;
    /*jx add 立即停止对下载状态的请求*/
    clearInterval(this.timer);
    this.timer = null;
    // console.log("人工stop");
    /**/
    try {
      // 调用发送停止下载请求的函数，并传递相应的参数,构造停止下载请求的参数
      const message ={
        sendmail_date:"stop",
        emailaccount_id:this.emailAccountForm.id,
        download_type:this.downloadForm.download_type,
      };
      const response = await emailstopdownload(message);
      const msg = response.data.body;
      var vmsg = JSON.parse(msg);
      console.log("responddsdsdssdddddddddd"+vmsg.error_msg);
      // 判断后端返回的错误码
      if (vmsg.error_code === 1) {
        // 终止成功，显示成功消息
        this.$message.success(vmsg.error_msg);
      } else {
        // 终止失败，显示错误消息
        this.$message.error(vmsg.error_msg);   
      }
    } catch (error) {
      // 处理请求失败的情况，显示错误消息
      this.$message.error('请求失败，请稍后重试');
      console.error(error);
    }
  },
    //重置
    resetdown() {
  // 重置与下载表单相关的数据
  this.downloadForm = {
    emailaccount_id: "",
    download_type: "",
    sendmail_date: "",
    datetype: "",
    message: "",
  };
  
  // 清空时间选择相关的数据
  this.start = "";
  this.end = "";
  this.timeData = "";
  
  // 关闭时间选择对话框
  this.dialogVisible = false;


      ////this.$ref.downloadForm.resetFields();
      
    },
    getMailList() {
      getemailaccount().then((response) => {
        if (response.error_code == 200) {
          this.accountandemail = response.data.results;
        }
      });
    },
    //获取邮件表结果
    getMailTask() {
      getemailtask().then((response) => {
        // console.log(response)
      });
    },
    async downloadEmail() 
    {
      this.isButtonDisabled = true;
      this.opendown();
      this.downloadForm.emailaccount_id = this.emailAccountForm.id;
      this.downloadForm.sendmail_date = this.startData;
      await emaildownload(this.downloadForm).then((response) => 
      {
        if (response.error_code == 200) 
        {
          this.loading = false;
          this.downloadForm.message = response.data.message;
          this.downloadForm.total = response.data.totals;
          if (response.data.totals == 0) 
          {
            //不需要下载
            this.progressflag = false;
          } 
          else 
          {
            //需要下载
            this.progressflag = true;
            // this.$message.success("下载成功");
            //根据id获取邮件下载详情
            if (response.data.taskid) 
            {
              console.log(222); 
              /*jx 23/12/03 modify：1.timer设为data属性为了点击停止按钮时可以调用；2.单独使用setinterval 没发现界面卡死*/
              let rateres;
              emaildownloadrate(response.data.taskid).then((res) => 
              {
                if(res.error_code == 200)
                {
                  this.timer = setInterval(() => 
                  {
                    console.log(333+"循环定时器");
                    //getemailtaskbyId(response.data.taskid);
                    emaildownloadrate(response.data.taskid).then((rateres)=>
                    {
                      this.percentage = rateres.data.progress;
                      console.log(rateres.data.progress);
                      console.log("ssssssssssssss");
                      //下载完成时则停止向后端请求
                      if(rateres.data.status == "SUCCESS")
                      { 
                        this.downloadComplete = true;
                        this.isBothButtonsDisabled = true;
                        clearInterval(this.timer);
                        const duplicateCount = rateres.data.repeat;
                        if(duplicateCount > 0)
                        {
                          if(!this.$alterrepeat)
                          {
                            let mess = '下载完成，共计'+rateres.data.totals+'封邮件，其中'+duplicateCount+'封重复，是否覆盖？'
                            this.$alterrepeat = this.$confirm(mess, '提示', 
                            {
                              confirmButtonText: '是',
                              cancelButtonText: '否', 
                              type:'warning'
                            }).then(()=>{
                              const info = "1"
                              const message =
                              {
                                sendmail_date:"stop",
                                emailaccount_id:this.emailAccountForm.id,
                                download_type:this.downloadForm.download_type,
                              };
                              DeletRepeatEmail(message);
                            }).catch(()=>{

})
// callback: action => 
// {
//   // 处理用户点击确定后的逻辑
//   if(action == confirm)
//   {
//     const info = "1"
//     const message =
//     {
//       sendmail_date:"stop",
//       emailaccount_id:this.emailAccountForm.id,
//       download_type:this.downloadForm.download_type,
//     };
//     DeletRepeatEmail(message);
//   }
// }
// });
                          }
                          
                        }
                        else
                        {
                          if(!this.$messagerepeat)
                          {
                            let mess = '没有异常重复邮件，下载完成！'
                            this.$messagerepeat = this.$message.success(mess);
                          }
                        }
                        
                      }
                    });
                    
                  },1000);
                }
              });
              /*jx add 新增提示框：重复邮件，是否覆盖*/
              // const duplicateCount = rateres.data.repeat;
              // if (rateres.data.status == "SUCCESS") 
              // {
              //   console.log("xiazai wancheng ")
              //   // const duplicateCoun =10;
              //   let mess = '下载完成，共有'+duplicateCount+'封重复邮件，是否覆盖？'
              //   this.$alert(mess, '提示', 
              //   {
              //     confirmButtonText: '确定',
              //     callback: action => 
              //     {
              //       // 处理用户点击确定后的逻辑
              //       DeletRepeatEmail();
              //     }
              //   });
              // }
            /**/
            /*原始逻辑；jx注释掉改成上面；23/12/03*/
            //轮询
            // let timer = setInterval(() => {
            //   console.log(333);

            //   //只用setInterval页面会卡死，和setTimeout结合使用
            //   setTimeout(() => {
            //     getemailtaskbyId(response.data.taskid).then((res) => {
            //       console.log(res);
            //     });
            //   }, 0);
            // }, 500)();
            // clearInterval(timer);
            /*新增功能:下载完成后提示用户有多少重复邮件，是否覆盖*/

            }
          }
        }
      });
    },
    timeFormat(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1 + "";
      month = month.length === 1 ? "0" + month : month;
      let day = date.getDate() + "";
      day = day.length === 1 ? "0" + day : day;
      return year + "-" + month + "-" + day;
    },
    filterTime(menu) {
      const interval = menu.interval;
      if (interval !== -1) {
        const end = new Date();
        const time = end.getTime() - interval * 24 * 60 * 60 * 1000;
        const start = new Date(time);
        const endStr = this.timeFormat(end);
        const startStr = this.timeFormat(start);
        this.timeData = startStr + " => " + endStr;
        this.startData = startStr;
      } else {
        this.start = "";
        this.end = "";
        this.dialogVisible = true;
      }
    },
    saveTime() {
      this.timeData = this.start + " => " + this.end;
      this.startData = this.start;
      this.dialogVisible = false;
      // this.timePo = Object.assign(this.timePo,
      //   {
      //     field: 'updated_at',
      //     start: this.start + this.split + '00:00:00',
      //     end: this.end + this.split + '23:59:59'
      //   }
      // )
      // this.fatherInit()
    },
    cancel() {
      this.dialogVisible = false;
    },
    // 时间开始选择器
    startTimeStatus: function (value) {
      this.start = this.timeFormat(value);
    },
    // 时间结束选择器
    endTimeStatus: function (value) {
      this.end = this.timeFormat(value);
    },
  },
  created() {
    this.currentId = this.$route.params.id;
    getinfouseraccount(this.currentId).then((response) => {
      if (response.error_code == 200) {
        this.emailAccountForm = response.data;
      }
    });
    this.getMailList();
  },
};
</script>

<style lang="scss">
.centered-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
}

.setting {
  height: 100%;
  width: 100%;
  .el-input__inner {
    height: 30px;
  }
  .left_account {
    font-size: larger;
    float: left;
    width: 35%;
    height: 100%;
    .btn_box {
      margin-top: 5px;
      margin-left: 50px;
    }
    .card {
      margin-bottom: 5%;
    }
  }
  .right_set {
    margin-left: 36%;
    width: 50%;
    height: 100%;
    .card {
      margin-bottom: 5%;
    }
    .el-input__inner {
      height: 30px;
    }
    .el-card__body {
      padding: 10px;
    }
    .col_box {
      margin: 3%;
      background-color: rgba(212, 219, 208, 0.28);
    }
    .el-progress {
      margin-top: 12px;
    }
  }
}
</style>
